<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent>
            <el-form-item label="产品">
              <el-input
                v-model="searchProductName"
                placeholder="输入产品名称别名、条码搜索"
                clearable
                @keyup.enter.native="handleSearch"
                @clear="handleSearch"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" @click="addProductBarCodeClick" size="small" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="ProductName" label="产品名称"></el-table-column>
        <el-table-column prop="Alias" label="产品别名"></el-table-column>
        <el-table-column prop="UnitName" label="包装单位"></el-table-column>
        <el-table-column label="包装单位数量">
           <template slot-scope="scope">
            <div>{{scope.row.Amount}} {{scope.row.MinimumUnitName}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="BarCode" label="产品包装单位条码"></el-table-column>
        <el-table-column prop="IsMinimumUnit" label="是否最小包装单位" :formatter="formatMiniUnit"></el-table-column>
        <el-table-column
          prop="IsDefautSendReceive"
          label="是否默认收发"
          :formatter="formatDefautSendReceive"
        ></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="editProductBarCodeClick(scope.row)"
              v-prevent-click
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="840px" @closed="cancelSubmitProductBarCode('addBarCodeRules')">
      <el-form
        :model="ruleForm"
        :rules="addBarCodeRules"
        ref="addBarCodeRules"
        label-width="130px"
        size="small"
        class="barCodeInfoFrom"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="ProductID">
              <el-select
                v-model="ruleForm.ProductName"
                clearable
                v-loadmore="loadMore"
                filterable
                remote
                placeholder="请选择产品"
                :remote-method="searchProductListMethod"
                @change="handleSelectProduct"
                :loading="productLoading"
                :default-first-option="true"
                value-key="ID"
                @clear="searchProductListMethod('')"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.ID"
                  :label="item.Name"
                  :value="item"
                >
                  <span style="float: left">
                    {{ item.Name }}
                    <span class="color_gray" v-if="item.Alias">({{ item.Alias }})</span>
                  </span>
                  <span class="font_12 color_999" style="float: right;">{{ item.UnitName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品包装单位条码" prop="barCode">
              <el-input v-model="ruleForm.barCode" placeholder="请输入产品条码" size="small"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="包装单位" prop="UnitID">
              <el-select filterable :default-first-option="true"
                :disabled="IsMinimumUnit"
                clearable
                v-model="ruleForm.UnitID"
                placeholder="请选择包装单位"
                size="small"
              >
                <el-option
                  v-for="item in unitList"
                  :key="item.ID"
                  :label="item.Name"
                  :value="item.ID"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="包装单位数量" prop="miniUnitNum">
              <span slot="label">
                包装单位数量
                <el-popover placement="top-start" width="240" trigger="hover">
                  <p>1.包装单位对应最小包装数量；</p>
                  <p>2.比如产品最小包装单位为“片”，5片为一盒，10盒为一箱；</p>
                  <p>3.如果包装单位为“箱”，则对应的包装单位数量为50；</p>
                  <p>4.如果包装单位为“盒”，则对应的包装单位数量为5；</p>
                  <p>5.注意：最小包装单位数量为1，且不能修改。</p>
                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                </el-popover>
              </span>
              <el-input
                :disabled="IsMinimumUnit"
                v-model="ruleForm.miniUnitNum"
                placeholder="请输入包装单位数量"
                size="small"
              >
                <template slot="append">{{minUnitName}}</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="默认收发" prop="IsDefautSendReceive">
              <el-radio-group v-model="ruleForm.IsDefautSendReceive">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSubmitProductBarCode('addBarCodeRules')" size="small" v-prevent-click>取 消</el-button>
        <el-button
          type="primary"
          @click="addSubmitProductBarCode('addBarCodeRules')"
          :loading="modalLoading"
          size="small"
          v-prevent-click
        >保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import APIBarCode from "@/api/PSI/Product/productBarCode";
import APIProduct from "@/api/PSI/Product/product";
import APIUnit from "@/api/PSI/Product/unit";

export default {
  name: "ProductBarCode",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      dialogVisible: false,
      modalLoading: false,
      loading: false,
      isEditBarCode: false,
      productLoading: false,
      IsMinimumUnit:false,
      dialogTitle: "",
      searchProductName: "", //搜索
      tableData: [],
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
      ruleForm: {
        ProductName: "",
        ProductID: "",
        barCode: "",
        UnitID: "",
        miniUnitNum: "",
        IsMinimumUnit: false,
        IsDefautSendReceive: false,
      },
      addBarCodeRules: {
        ProductID: [
          {
            required: true,
            message: "请选择产品",
            trigger: ["blur", "change"],
          },
        ],
        UnitID: [
          {
            required: true,
            message: "请选择包装单位",
            trigger: ["blur", "change"],
          },
        ],
        miniUnitNum: [
          { required: true, message: "请输入最小单位数量", trigger: "blur" },
        ],
      },
      productPageNum: 1,
      productTotal: 0,
      productList: [], //产品列表
      unitList: [], //包装单位列表
      minUnitName: "", //选中产品的的最小单位
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**  点击事件  */
    /**  列表搜索  */
    handleSearch() {
      var that = this;
      that.paginations.page = 1;
      that.getProductBarCodeListNetwork();
    },
    /**  修改页码  */
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getProductBarCodeListNetwork();
    },
    /**  是否最小包装单位  */
    formatMiniUnit(item) {
      return item.IsMinimumUnit ? "是" : "否";
    },
    /**  是否默认收发  */
    formatDefautSendReceive(item) {
      return item.IsDefautSendReceive ? "是" : "否";
    },

    /**  新增条码  */
    addProductBarCodeClick() {
      var that = this;
      that.ruleForm = {
        ProductName: "",
        ProductID: "",
        barCode: "",
        UnitID: "",
        miniUnitNum: "",
        IsMinimumUnit: false,
        IsDefautSendReceive: false,
      };
      that.dialogTitle = "新增产品包装单位";
      that.minUnitName = "";
      that.searchProductListMethod("");
      that.dialogVisible = true;
      that.isEditBarCode = false;
      that.IsMinimumUnit = false
    },
    /**  编辑条码  */
    editProductBarCodeClick(row) {
      var that = this;
      that.isEditBarCode = true
      that.dialogTitle = "编辑产品包装单位";
      that.IsMinimumUnit = row.IsMinimumUnit;
      that.minUnitName = row.MinimumUnitName;
      that.ruleForm = {
        ID: row.ID,
        ProductID: row.ProductID,
        ProductName:row.ProductName,
        barCode: row.BarCode,
        UnitID: row.UnitID,
        miniUnitNum: row.Amount,
        IsMinimumUnit: row.IsMinimumUnit,
        IsDefautSendReceive: row.IsDefautSendReceive,
      };
      that.dialogVisible = true;
    },
    
    /**  取消baocn  */
    cancelSubmitProductBarCode(){
      var that = this
      that.$refs["addBarCodeRules"].resetFields();
      that.dialogVisible = false
    },

    /**  保存 产品条码信息  */
    addSubmitProductBarCode() {
      var that = this;
      that.$refs["addBarCodeRules"].validate((valid)=>{ 
        if(valid){
          if (that.isEditBarCode) {
            that.updateProductBarCodeUpdateNetwork();
          } else {
            that.saveProductBarCodeCreateNetwork();
          }
        }

      })
    },
    /**  选择产品  */
    handleSelectProduct(item) {
      var that = this;
      that.ruleForm.ProductID = item.ID;
      that.minUnitName = item.UnitName;
    },
    /**  产品上拉加载更多  */
    loadMore() {
      var that = this;
      if (that.productTotal > that.productList.length) {
        that.productPageNum++;
        that.getProductInfoListNetWork();
      }
    },
    /**  产品远程搜索  */
    searchProductListMethod(query) {
      var that = this;
      that.productLoading = true;
      that.productPageNum = 1;
      that.productList = [];
      that.getProductInfoListNetWork(query.trim());
    },

    /**  网络请求  */
    /**  获取条码列表  */
    getProductBarCodeListNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        ProductName: that.searchProductName,
      };

      APIBarCode.getProductBarCodeList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  请求产品列表  */
    getProductInfoListNetWork(queryString) {
      if (!queryString) {
        queryString = "";
      }
      var that = this;
      var params = {
        Name: queryString,
        Active: true,
        PageNum: that.productPageNum,
      };
      APIProduct.getProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productTotal = res.Total;
            that.productList.push.apply(that.productList, res.List);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.productLoading = false;
        });
    },
    /**  获取包装单位列表  */
    getUnitList: function () {
      var that = this;
      that.loading = true;
      APIUnit.getUnitAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.unitList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  添加产品条码  */
    saveProductBarCodeCreateNetwork: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        ProductID: that.ruleForm.ProductID,
        BarCode: that.ruleForm.barCode,
        UnitID: that.ruleForm.UnitID,
        Amount: that.ruleForm.miniUnitNum,
        IsMinimumUnit: that.ruleForm.IsMinimumUnit,
        IsDefautSendReceive: that.ruleForm.IsDefautSendReceive,
      };

      APIBarCode.getProductBarCodeCreate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.dialogVisible = false;
            that.$refs["addBarCodeRules"].resetFields();
            that.getProductBarCodeListNetwork();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    /**  更新产品条码  */
    updateProductBarCodeUpdateNetwork: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        ID: that.ruleForm.ID,
        ProductID: that.ruleForm.ProductID,
        BarCode: that.ruleForm.barCode,
        UnitID: that.ruleForm.UnitID,
        Amount: that.ruleForm.miniUnitNum,
        IsMinimumUnit: that.ruleForm.IsMinimumUnit,
        IsDefautSendReceive: that.ruleForm.IsDefautSendReceive,
      };

      APIBarCode.getProductBarCodeUpdate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.dialogVisible = false;
            that.$refs["addBarCodeRules"].resetFields();
            that.getProductBarCodeListNetwork();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
  },
  /**  实例被挂载后调用  */
  mounted() {
    var that = this;
    that.getProductBarCodeListNetwork();
    that.getProductInfoListNetWork();
    that.getUnitList();
  },
};
</script>

<style scoped lang="scss">
/deep/ .autocomplete_li_class {
  li {
    padding: 5px 15px;
    line-height: normal;
  }
}
/deep/ .barCodeInfoFrom {
  .el-form-item__label {
    font-size: 13px !important;
  }
  .el-form-item__content {
    font-size: 13px !important;
  }
  // .el-form-item {
  //   margin-bottom: 0px;
  // }
}
</style>
