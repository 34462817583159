/**
 * Created by jerry on 2020/02/14.
 * 包装单位api
 */
import * as API from '@/api/index'


export default {
    // 获取包装单位列表
    getUnitAll: params => {
        return API.POST('api/unit/all', params)
    },
    // 获取包装单位列表
    getUnit: params => {
        return API.POST('api/unit/list', params)
    },
    // 创建包装单位
    createUnit: params => {
        return API.POST('api/unit/create', params)
    },
    // 编辑更新包装单位
    updateUnit: params => {
        return API.POST('api/unit/update', params)
    },
}
