/**
 * Created by jerry on 2020/02/14.
 * 货品资料api
 */
import * as API from '@/api/index'

export default {
    // 获取货品资料列表
    getProduct: params => {
        return API.POST('api/product/list', params)
    },
    // 创建产品资料
    createProduct: params => {
        return API.POST('api/product/create', params)
    },
    // 编辑更新包装单位
    updateProduct: params => {
        return API.POST('api/product/update', params)
    },
}
